import "./App.css";
import "./l1xapplandingpage.css";

import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";

import { Header } from "./components/header";
import { Footer } from "./components/footer";
import { Error } from "./components/error";
import HeaderMobileMenu from "./components/header-mobile-menu";

import { Home } from "./components/home";
import { StartBuilding } from "./components/start-building";
import { JoinWaitlist } from "./components/join-waitlist";
import { About } from "./components/about-us";
import { BuildonLayerOneX } from "./components/build-on-layeronex";
import { HackathonRegistation } from "./components/hackathon-registration";
import { GrantApplications } from "./components/grant-applications";
import { ConsensusMechanism } from "./components/consensus-mechanism";
import { BuyL1X } from "./components/buy-l1x";
import { JoinTheTeam } from "./components/join-the-team";
import { NativeTransactionLifecycle } from "./components/native-transaction-lifecycle";
import { InteroperableTransactionLifecycle } from "./components/interoperable-transaction-lifecycle";
import { HowItWorks } from "./components/how-it-works";
import { UseCases } from "./components/use-cases";
import { DeFi } from "./components/defi";
import { Blog } from "./components/blog";
import { BuyL1XProico } from "./components/buy-l1x-2";
import { JoinourWhitelisting } from "./components/join-our-whitelisting";
import { InvestorWebinarRegistration } from "./components/investor-webinar-registration";

import GetInTouch from "./components/get-in-touch";
import Referral from "./components/referral";

import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import Admin from "./components/admin/index";
import AdminLogin from "./components/admin/login";
import TermsOfServices from "./components/termsOfServices";
import PrivacyPolicy from "./components/privacyPolicy";
import CookiePolicies from "./components/cookiePolicies";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
// import { DirectInvestment } from "./components/private-sale/directInvestment";

import Config from "./config";
import WhiteListTermsCondition from "./components/WhitelistTermConditions";
import NftComponent from "./components/nft";
import NftGiveaway from "./components/nft/nftGiveaway";
import NftTerms from "./components/nft/nftTerms";
import NftHowItWorks from "./components/nft/nftHowItWorks";
import TokenCalulator from "./components/calculator";
import RevealNFTWinner from "./components/reveal-nft-winner";
import { PublicSale } from './components/public-sale/publicSale';
import { PreRegistration } from './components/public-sale/preRegistration';
import { PublicSaleBuySection } from './components/public-sale/publicSaleBuySection';
// import { TimerContainer } from "./components/public-sale/timerContainer";
import WalletPage from "./components/wallet-page";
import WalletHome from "./components/wallet/walletHome";

import PremiumWalletList from "./components/premiumWallet/index";
//import MoonpayReconcilation from "./components/moonPayReconciliation/index";
import BankTransferReconcilation from "./components/bankTransferReconciliation/index";
import { PublicSaleBanner } from "./components/public-sale/publicSaleBanner";
import ForgetReferral from "./components/referral/forgetReferral";
import NonFungible from "./components/nonFungible";
import PrivateSaleInvestors from "./components/privateSaleInvestors/index";
import UploadCsvSale from "./components/saleCsvUpload/index";
import EarlyAccessList from './components/earlyAccessExplicitList/index'
// import { TimerContainer } from "./components/public-sale/timerContainer";
// import TermsAndConditions from "./components/termsAndConditions";
import L1xAppLandingPage from "./components/l1xappLandingPage";


// import PrivateSale from './components/private-sale';
// import Navigation from './components/navigation';
// import { Wyde } from './components/wyde';
// import { Faqs } from './components/faqs';

// export const ThemeContext = createContext(null);
//sentry initialization
Sentry.init({
  dsn: Config.sentry.dsn,
  integrations: [new BrowserTracing(), new Sentry.Replay()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

class App extends Component {
  state = { theme: "dark" };
  isAdmin = window.location.pathname.includes("/admin");
  isWallet = window.location.pathname.includes("/wallet");
  isNft = window.location.pathname.includes("/nft");

  // this.toggleTheme = this.toggleTheme.bind(this) ;

  // const [theme, setTheme] = useState("dark");
  toggleTheme = () => {
    // Binding this keyword

    // Changing state
    this.setState({
      theme: this.state.theme === "light" ? "dark" : "light",
    });
  };

  render() {
    return (
      <div className="App" id={this.state.theme}>
        {/* <Navigation /> */}

        {this.isAdmin === false ? this.isWallet === false && (
          <>
            <Header
              toggleTheme={this.toggleTheme}
              themeEnabled={this.state.theme}
            ></Header>
            <HeaderMobileMenu
              toggleTheme={this.toggleTheme}
              themeEnabled={this.state.theme}
            ></HeaderMobileMenu>
          </>
        ) : (
          ""
        )}

        {/* {this.isAdmin === false ? (
          <>
            <PublicSaleBanner
              toggleTheme={this.toggleTheme}
              themeEnabled={this.state.theme}
            />
          </>
        ) : (
          ""
        )} */}
        <Routes>
          <Route
            exact
            path=""
            element={
              <Home
                toggleTheme={this.toggleTheme}
                themeEnabled={this.state.theme}
              ></Home>
            }
          />
          <Route
            exact
            path="*"
            element={
              <Error
                toggleTheme={this.toggleTheme}
                themeEnabled={this.state.theme}
              ></Error>
            }
          />

          <Route
            exact
            path="start-building"
            element={
              <StartBuilding
                toggleTheme={this.toggleTheme}
                themeEnabled={this.state.theme}
              ></StartBuilding>
            }
          />

          <Route
            exact
            path="join-hackathon-waitlist"
            element={
              <JoinWaitlist
                toggleTheme={this.toggleTheme}
                themeEnabled={this.state.theme}
              ></JoinWaitlist>
            }
          />

          <Route
            exact
            path="get-in-touch"
            element={
              <GetInTouch
                toggleTheme={this.toggleTheme}
                themeEnabled={this.state.theme}
              ></GetInTouch>
            }
          />

          <Route
            exact
            path="about-us/who-we-are"
            element={
              <About
                toggleTheme={this.toggleTheme}
                themeEnabled={this.state.theme}
              ></About>
            }
          />
          <Route
            exact
            path="about-us/join-the-team"
            element={
              <JoinTheTeam
                toggleTheme={this.toggleTheme}
                themeEnabled={this.state.theme}
              ></JoinTheTeam>
            }
          />
          <Route
            exact
            path="build-on-layeronex"
            element={
              <BuildonLayerOneX
                toggleTheme={this.toggleTheme}
                themeEnabled={this.state.theme}
              ></BuildonLayerOneX>
            }
          />
          <Route
            exact
            path="build/hackathon-registration"
            element={
              <HackathonRegistation
                toggleTheme={this.toggleTheme}
                themeEnabled={this.state.theme}
              ></HackathonRegistation>
            }
          />
          <Route
            exact
            path="build/grant-applications"
            element={
              <GrantApplications
                toggleTheme={this.toggleTheme}
                themeEnabled={this.state.theme}
              ></GrantApplications>
            }
          />
          <Route
            exact
            path="build/consensus-mechanism"
            element={
              <ConsensusMechanism
                toggleTheme={this.toggleTheme}
                themeEnabled={this.state.theme}
              ></ConsensusMechanism>
            }
          />
          <Route
            exact
            path="build/native-transaction-lifecycle"
            element={
              <NativeTransactionLifecycle
                toggleTheme={this.toggleTheme}
                themeEnabled={this.state.theme}
              ></NativeTransactionLifecycle>
            }
          />
          <Route
            exact
            path="build/interoperable-transaction-lifecycle"
            element={
              <InteroperableTransactionLifecycle
                toggleTheme={this.toggleTheme}
                themeEnabled={this.state.theme}
              ></InteroperableTransactionLifecycle>
            }
          />

          {/* <Route
            exact
            path="invest/buy-l1x"
            element={
              <BuyL1X
                toggleTheme={this.toggleTheme}
                themeEnabled={this.state.theme}
              ></BuyL1X>
            }
          /> */}
          {/* <Route
            exact
            path="invest/buy-l1x/:referrerCode"
            element={
              <BuyL1X
                toggleTheme={this.toggleTheme}
                themeEnabled={this.state.theme}
              ></BuyL1X>
            }
          /> */}
          <Route
            exact
            path="invest/buy-l1x/:referrerCode"
            element={
              <PublicSale
                toggleTheme={this.toggleTheme}
                themeEnabled={this.state.theme}
              ></PublicSale>
            }
          />
          <Route
            exact
            path="invest/buy-l1x-proico"
            element={
              <BuyL1XProico
                toggleTheme={this.toggleTheme}
                themeEnabled={this.state.theme}
              ></BuyL1XProico>
            }
          />
          {/* <Route
            exact
            path="invest/direct"
            element={
              <DirectInvestment
                toggleTheme={this.toggleTheme}
                themeEnabled={this.state.theme}
              />
            }
          /> */}
          <Route
            exact
            path="token-price-calculator"
            element={<TokenCalulator />}
          />
          <Route
            exact
            path="reveal-nft-winner-4jKw1vzHt8BxL9NQcDfS2nE5"
            element={<RevealNFTWinner />}
          />

          <Route
            exact
            path="how-it-works"
            element={
              <HowItWorks
                toggleTheme={this.toggleTheme}
                themeEnabled={this.state.theme}
              ></HowItWorks>
            }
          />

          <Route
            exact
            path="l1x-app"
            element={
              <L1xAppLandingPage
                toggleTheme={this.toggleTheme}
                themeEnabled={this.state.theme}
              ></L1xAppLandingPage>
            }
          />

          <Route
            exact
            path="use-cases"
            element={
              <UseCases
                toggleTheme={this.toggleTheme}
                themeEnabled={this.state.theme}
              ></UseCases>
            }
          />
          <Route
            exact
            path="use-cases/defi"
            element={
              <DeFi
                toggleTheme={this.toggleTheme}
                themeEnabled={this.state.theme}
              ></DeFi>
            }
          />
          <Route
            exact
            path="blog"
            element={
              <Blog
                toggleTheme={this.toggleTheme}
                themeEnabled={this.state.theme}
              ></Blog>
            }
          />
          <Route
            exact
            path="join-our-whitelisting"
            element={
              <JoinourWhitelisting
                toggleTheme={this.toggleTheme}
                themeEnabled={this.state.theme}
              ></JoinourWhitelisting>
            }
          />
          <Route
            exact
            path="investor-webinar-registration"
            element={
              <InvestorWebinarRegistration
                toggleTheme={this.toggleTheme}
                themeEnabled={this.state.theme}
              ></InvestorWebinarRegistration>
            }
          />

          {/* <Route exact path="buy" element={<PrivateSale />} /> */}
          {/* <Route exact path="buy/:referrerCode" element={<PrivateSale />} /> */}

          <Route
            exact
            path="buy/:referrerCode"
            element={
              <BuyL1X
                toggleTheme={this.toggleTheme}
                themeEnabled={this.state.theme}
              ></BuyL1X>
            }
          />

          <Route exact path="referral" element={<Referral />} />
          <Route exact path="forget-referral-code" element={<ForgetReferral />} />

          <Route exact path="terms-of-services" element={<TermsOfServices />} />
          <Route exact path="privacy-policy" element={<PrivacyPolicy />} />

          <Route exact path="/123456/tokens" element={<NonFungible />} />

          {/* <Route exact path="terms-and-conditions" element={<TermsAndConditions />} /> */}


          <Route
            exact
            path="whitelist-terms-conditions"
            element={<WhiteListTermsCondition />}
          />
          <Route exact path="cookie-policies" element={<CookiePolicies />} />

          <Route exact path="nft" element={<NftComponent />} />

          <Route exact path="nft/giveaway" element={<NftGiveaway />} />

          <Route exact path="nft/terms" element={<NftTerms />} />

          <Route exact path="nft/how-it-works" element={<NftHowItWorks />} />

          {/* wallet sale routes */}
          {/* <Route exact path="wallet" element={<WalletPage />} /> */}

          {/* Public sale routes */}
          <Route exact path="public-sale" element={<PublicSale />} />
          <Route exact path="public-sale/:referrerCode" element={<PublicSale />} />
          <Route exact path="public-sale/pre-registration" element={<PreRegistration />} />
          <Route exact path="public-sale/aelgbbpmtcxnqxylgtgzmlbwtabbjhlp" element={<PublicSaleBuySection />} />

          {/* Wallet routes */}
          {/* <Route exact path="wallet" element={<WalletHome />} /> */}
          <Route exact path="admin/premium/wallet-list" element={<PremiumWalletList />} />
          {/* <Route exact path="admin/moon-pay-reconciliation" element={<MoonpayReconcilation />} /> */}
          <Route exact path="admin/banktransfer-reconciliation" element={<BankTransferReconcilation />} />
          <Route exact path="admin/private-sale-investors" element={<PrivateSaleInvestors />} />
          <Route exact path="admin/early-access" element={<UploadCsvSale />} />
          <Route exact path="admin/early-access-explicit-list" element={<EarlyAccessList />} />

          {/* <Route exact path="integrations/wyde" element={<Wyde toggleTheme={this.toggleTheme} themeEnabled={this.state.theme} ></Wyde>} />
              <Route exact path="faqs" element={<Faqs toggleTheme={this.toggleTheme} themeEnabled={this.state.theme} ></Faqs>} /> */}

          {/*Admin section routes start  */}

          <Route
            exact
            path={Config.adminUrlHash + "/admin/dashboard"}
            element={<Admin path="dashboard" />}
          />
          <Route
            exact
            path={Config.adminUrlHash + "/admin-login"}
            element={<AdminLogin />}
          />
          <Route
            exact
            path={Config.adminUrlHash + "/admin/prospects"}
            element={<Admin path="prospects" />}
          />
          <Route
            exact
            path={Config.adminUrlHash + "/admin/prospect-transactions"}
            element={<Admin path="prospect-transactions" />}
          />
          <Route
            exact
            path={Config.adminUrlHash + "/admin/onchain-config"}
            element={<Admin path="onchain-config" />}
          />




          {/*Admin section routes end  */}
        </Routes>
        {/* <Home toggleTheme={this.toggleTheme} themeEnabled={this.state.theme} ></Home> */}
        {this.isAdmin === false ? this.isWallet === false && (
          <>
            <Footer
              toggleTheme={this.toggleTheme}
              themeEnabled={this.state.theme}
            ></Footer>
          </>
        ) : (
          ""
        )}
        <NotificationContainer />

        <Routes></Routes>
      </div>
    );
  }
}

export default App;
