/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import TickIcon from "../images/icons/tick-icon.png";
import LMTickIcon from "../images/icons/tick-icon-lm.png";
import Benefit from "../images/icons/benefit-icon.png";
import LMBenefit from "../images/icons/benefit-icon-lm.png";
import Upload from "../images/icons/upload.png";

import PropTypes from "prop-types";

import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from "react-router-dom";
import Config from "../config";

export class JoinTheTeam extends Component {

  componentDidMount() {
    AOS.init();
  }

  render() {
    // const theme=this.props



    return (
      <>
        <section className="banners-section sub-banner">
          <nav className="breadcrumbs">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="fa-solid fa-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a>About Us</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Join The Team
                </li>
              </ol>
            </div>
          </nav>
          <div className="banner-section text-center d-flex align-self-center">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 m-auto">
                  <h4 data-aos="fade-up">Join The Team</h4>
                  <h1 className="mb-32" data-aos="fade-up" data-aos-delay="300">
                    Join our community of
                    <br /> developers. Build the future.
                  </h1>
                  <div className="buttons d-flex justify-content-center" data-aos="fade-up" data-aos-delay="400">
                    <div className="btn-style btn-style-one mb-0">
                      <Link to="/start-building" className="glow-button gradient-border-mask">
                        <span>Get in Touch</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section web3-usecases our-goals text-center">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h3 className="text-left" data-aos="fade-up">
                  Let’s work together on  <br />
                  Decentralised Technology
                </h3>
              </div>
              <div className="col-md-6">
                <p className="pb-32 text-left" data-aos="fade-up" data-aos-delay="100">
                  The market for dApps development is ripe for innovation and growth. As layer two platforms struggle to effectively scale web applications on the blockchain, there is a growing need for a powerful layer one solution that enables rapid dApps development and seamless interoporability without sacrificing security and scalability. Layer One X is that solution. Built from the ground up to facilitate unrivalled interoperability across different blockchains, Layer One X empowers developers to quickly and easily build applications that are decentralized, scalable, and secure. With its innovative approach to lightweight, integrated multi-blockchain technology, multi-permission systems, and consensus reliance in a single event, Layer One X opens up new possibilities for developers.
                </p>
                <p className="pb-80 text-left" data-aos="fade-up" data-aos-delay="200">
                Layer One X revolutionary technology brings a new paradigm of scalability and interoperability to the market. By successfully addressing the trilemma of decentralization, security, and scalability, Layer One X achieves all three without compromising either. The Layer One X architecture is designed on principles that the solution should be democratic and applicable to any blockchain network (public, private, or hybrid) and any industry use case. Also that the solution should be compatible with existing blockchain networks and allow for the implementation of new blockchain capabilities and use cases.
                </p>
              </div>
            </div>
            <div className="cards-section benefits-align">
              <div className="row justify-content-center">
                <div className="col-md-4">
                  <div className="card mb-32" data-aos="fade-up" data-aos-delay="0">
                    <figure className="mb-20">
                      <img src={
                        this.props.themeEnabled === "light"
                          ? LMBenefit
                          : Benefit
                      }
                        alt="Benefit" className="img-fluid" />
                    </figure>
                    <div className="box">
                      <h4>Decentralisation</h4>
                      <p>
                      Layer One X provides decentralisation in a true sense without any control of mining pools or token holders on the Layer One X blockchain. This is achieved by mobile-enabled transaction validation and unconditional proof of participation. More than 10,000 nodes would serve as validators in the Layer One X network.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card mb-32" data-aos="fade-up" data-aos-delay="100">
                    <figure className="mb-20">
                      <img src={
                        this.props.themeEnabled === "light"
                          ? LMBenefit
                          : Benefit
                      }
                        alt="Benefit" className="img-fluid" />
                    </figure>
                    <div className="box">
                      <h4>Security</h4>
                      <p>
                        A high level of security is provided by employing an innovative Proof of Participation-based consensus mechanism and an innovative integrity-based validation process.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card mb-32" data-aos="fade-up" data-aos-delay="200">
                    <figure className="mb-20">
                      <img src={
                        this.props.themeEnabled === "light"
                          ? LMBenefit
                          : Benefit
                      }
                        alt="Benefit" className="img-fluid" />
                    </figure>
                    <div className="box">
                      <h4>Scalability</h4>
                      <p>
                        Leveraging the benefits of validation through mobile devices, Layer One X offers a highly scalable network with fast confirmation and high throughput. Layer One X aims to achieve a transaction speed greater than 100,000 transactions per second. The Layer One X network creates blocks in less than 500 milliseconds and offers negligible transaction cost to finality i.e., less than $0.00001.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card" data-aos="fade-up" data-aos-delay="300">
                    <figure className="mb-20">
                      <img src={
                        this.props.themeEnabled === "light"
                          ? LMBenefit
                          : Benefit
                      }
                        alt="Benefit" className="img-fluid" />
                    </figure>
                    <div className="box">
                      <h4>Interoperability</h4>
                      <p>
                      Layer One X enables the ability to exchange value across blockchains using a single protocol. Nucleus scripting and flash contracts in Layer One X facilitate seamless interoperability in value exchange.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card" data-aos="fade-up" data-aos-delay="400">
                    <figure className="mb-20">
                      <img src={
                        this.props.themeEnabled === "light"
                          ? LMBenefit
                          : Benefit
                      }
                        alt="Benefit" className="img-fluid" />
                    </figure>
                    <div className="box">
                      <h4>Migration</h4>
                      <p>
                      Layer One X provides the ability to migrate using smart contracts and describe business rules. Developers can easily take advantage of Layer One X capabilities through open-source SDK. Businesses can easily reduce heavy upfront and maintenance costs without exposing how they do it.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section web3-usecases our-goals looking-for text-center ">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 data-aos="fade-up" data-aos-delay="0">
                  Who are we looking for?
                </h2>
                <p className="pb-80" data-aos="fade-up" data-aos-delay="100">We are seeking developers and organisations with strong programming skills, willing to work on the latest blockchain developments.
                  We are willing to partner with organisations and individual developers, to work on some of the most exciting solutions </p>
              </div>
            </div>
            <div className="cards-section benefits-align">
              <div className="row justify-content-center">
                <div className="col">
                  <div className="card mb-32" data-aos="fade-up" data-aos-delay="0">
                    <figure className="mb-20">
                      <img src={
                        this.props.themeEnabled === "light"
                          ? LMTickIcon
                          : TickIcon
                      }
                        alt="TickIcon" className="img-fluid" />
                    </figure>
                    <div className="box">
                      <h4>Dev. Skills</h4>
                      <p>
                        Experience across a range of development solutions in languages such as Solidity, Rust and many others, including open source.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card mb-32" data-aos="fade-up" data-aos-delay="100">
                    <figure className="mb-20">
                      <img src={
                        this.props.themeEnabled === "light"
                          ? LMTickIcon
                          : TickIcon
                      }
                        alt="TickIcon" className="img-fluid" />
                    </figure>
                    <div className="box">
                      <h4>Web 3</h4>
                      <p>
                        Experience with Web3/5, Smart Contracts and supply chains, able to provide coding samples, or portfolios of your work.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card mb-32" data-aos="fade-up" data-aos-delay="200">
                    <figure className="mb-20">
                      <img src={
                        this.props.themeEnabled === "light"
                          ? LMTickIcon
                          : TickIcon
                      }
                        alt="TickIcon" className="img-fluid" />
                    </figure>
                    <div className="box">
                      <h4>Protocols</h4>
                      <p>
                        Experience across multiple blockchain platforms and protocols such as Ethereum, Solana, Polkadot, Avalanche and others.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card" data-aos="fade-up" data-aos-delay="300">
                    <figure className="mb-20">
                      <img src={
                        this.props.themeEnabled === "light"
                          ? LMTickIcon
                          : TickIcon
                      }
                        alt="TickIcon" className="img-fluid" />
                    </figure>
                    <div className="box">
                      <h4>Cryptography</h4>
                      <p>
                        Developers with good knowledge of cryptography.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card" data-aos="fade-up" data-aos-delay="400">
                    <figure className="mb-20">
                      <img src={
                        this.props.themeEnabled === "light"
                          ? LMTickIcon
                          : TickIcon
                      }
                        alt="TickIcon" className="img-fluid" />
                    </figure>
                    <div className="box">
                      <h4>Evolve</h4>
                      <p>
                        Willing to continuously evolve and update your skills.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="section bg-blue text-center join-our-community">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-10 col-xl-7 m-auto">
                <h3 className="mb-0 pb-24" data-aos="zoom-in" data-aos-delay="100">
                  Help us build the future of decentralised <br />
                  blockchain technology
                </h3>
                <p className="pb-40" data-aos="fade-up" data-aos-delay="200">
                  The genie is out of the bottle and decentralisation is here to stay</p>
                <form className='get-in-touch m-0' data-aos="fade-up" data-aos-delay="300">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="John Sm"
                    />
                    <label>First Name</label>
                  </div>
                  <div className="form-floating">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="support@example.com"
                    />
                    <label>Email</label>
                  </div>
                  <div className="form-floating">
                    <input type="text" className="form-control" placeholder="+1 (555) 123-4567" />
                    <label>Phone</label>
                  </div>
                  <div className="form-floating upload">
                    {/* <input type="file" id="upload" className="form-control"/>
          <label for="upload">Upload your CV 
          </label>  */}
                    <div className="form-control">

                      <input type="file" name="file-7[]" id="file-7" className="inputfile inputfile-6" data-multiple-caption="{count} files" multiple="" />
                      <label for="file-7">
                        <strong>Upload your CV <img src={Upload}
                          alt="Upload"
                          className="uploadimg"
                        /> </strong></label>

                    </div>
                  </div>
                  <div className="form-floating">
                    <textarea className="form-control" placeholder="Anything else?"></textarea>
                  </div>
                  <div className="d-grid buttons">
                    <div className="btn-style btn-style-one m-0">
                      <button className="glow-button gradient-border-mask">
                        <span>Apply Today</span>
                      </button>
                    </div>
                  </div>

                </form>


              </div>
            </div>
          </div>
        </section>


        <section className="decentralised-section text-center d-flex align-self-center">
          <div className="container">
            <div className="row h-full align-items-center">
              <div className="col-sm-10 m-auto">
                <h2 data-aos="zoom-in" data-aos-delay="100">
                  Built to unite a <br />
                  decentralised future.
                </h2>
                <p data-aos="fade-up" data-aos-delay="200">
                  Create the next leap forward with interoperable, decentralised
                  and scalable Web3 infrastructure made for next-gen dApp
                  development.
                </p>
                <div className="buttons d-flex justify-content-center" data-aos="fade-up" data-aos-delay="300">
                  <div className="btn-style btn-style-one">
                    <Link className="glow-button gradient-border-mask" to="public-sale">
                      {/* <span>Buy L1X</span> */}
                      <span>{Config.publicSaleButtonLabel}</span>
                    </Link>
                  </div>

                  <div className="btn-style  btn-style-two">
                    <Link className="glow-button gradient-border-mask" to="/build/build-on-layeronex">
                      <span>Build on Layer One X</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
JoinTheTeam.propTypes = {
  theme: PropTypes.string,
};
