/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import 'react-notifications/lib/notifications.css';
import Config from "../config/index";
import logger from "../utils/logger";
import Notify from "../utils/notify";

export class StartBuilding extends Component {

  constructor(props) {
    super(props);
    this.state = {
      processingForm: false
    };
  }

  async handleSubmitForm(e) {


    

    e.preventDefault();

    if (this.state.processingForm === true) {
      return;
    }

 

    try {
      let _hubspotForm = {

        "submittedAt": (new Date()).getTime(),
        "fields": [
        {
          "name": "firstname",
          "value": e.target.name.value
        },
        {
          "name": "email",
          "value": e.target.email.value
        },
        {
          "name": "mobilephone",
          "value": e.target.phone.value
        },
        {
          "name": "referrer_code",
          "value": e.target.referrer_code.value
        },
        {
          "name": "i_am_or_represent_a",
          "value": e.target.field.value
        },
        {
          "name": "message",
          "value":  e.target.message.value
        } 
        ],
        "context": {
          // "hutk": ":hutk",
          "pageUri": "l1x.foundation",
          "pageName": "l1x.foundation official - start-building"
        },
        "legalConsentOptions":{ 
          "consent": { // Include this object when GDPR options are enabled
            "consentToProcess": true,
            "text": "I agree to allow L1X to store and process my personal data.",
            "communications": [
              {
                "value": true,
                "subscriptionTypeId": 999,
                "text": "I agree to receive marketing communications from L1X."
              }
            ]
          }
        }
      }


      this.setState({processingForm:true});

      await fetch(Config.hubspot.formUri, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          'Accept': 'application/json',
          'Content-Type':'application/json; charset=utf-8'
        },
        body: JSON.stringify(_hubspotForm)
      });
      
      Notify("Thank you for reaching out, We'll get back to very soon. ","success");
      e.target.reset();
      this.setState({processingForm:false});

    } catch (error) {
      Notify("Error while submitting enquiry","error");
      this.setState({processingForm:false});
      logger("error", error)
    }

  }

  render() {
    // const theme=this.props

    return (
      <>
        <div className="bg-banner">
          <section className="banners-section sub-banner">
            <nav className="breadcrumbs">
              <div className="container">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                  <Link to="/">
                      <i className="fa-solid fa-home"></i>
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <a>Connect</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Start Building
                  </li>
                </ol>
              </div>
            </nav>
            <div className="banner-section text-center d-flex align-self-center">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <h4>Start Building</h4>
                    <h1>
                      Build the world of Web3 with <br />
                      Layer One X.                      
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
          </div>

          <section className="section bg-blue text-left">
            <div className="container">
              <div className="row">
                <div className="col-md-5">
                  <h3 className="mb-0">Get in touch</h3>
                  <p>Contact our team we will be happy to assist you </p>
                  <p className="quick">
                    {/* <a href="tel:+1 (555) 123-4567">
                      <i className="fa-solid fa-phone"></i> +1 (555) 123-4567
                    </a> */}
                    <a href="mailto:support@l1x.foundation">
                      <i className="fa-regular fa-envelope"></i>{" "}
                      support@l1x.foundation
                    </a>
                    <a href='https://discord.gg/X67bKBZJYe' target={'_blank'}>
                      <i className="fa-brands fa-discord"></i> Join The Discord
                      Community
                    </a>
                  </p>
                </div>
                <div className="col-md-7">
                  
                <form className="get-in-touch" onSubmit={(e) => this.handleSubmitForm(e)}>
                  <div className="form-floating">
                    <input
                      type="text"
                      id='name'
                      name="name"
                      className="form-control"
                      placeholder="John Sm"
                      required
                    />
                    <label>First Name</label>
                  </div>
                  <div className="form-floating">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-control"
                      placeholder="support@example.com"
                      required
                    />
                    <label>Email</label>
                  </div>
                  <div className="form-floating">
                    <input
                      id="phone"
                      type="text"
                      name="phone"
                      className="form-control"
                      placeholder="Please include Country Code with Phone Number"
                      required
                    />
                    <label>Phone (Please include Country Code)</label>
                  </div>
                  <div className="form-floating">
                    <input
                      id="referrer_code"
                      type="text"
                      name="referrer_code"
                      className="form-control"
                      placeholder="Who Referred You to L1X?"
                    />
                    <label>Referrer Code</label>
                  </div>
                  <div className="form-floating select">
                    <select className="form-control"  name="field" id="field"  required>
                      <option value="">I am or represent a</option>
                      <option value="Developer">Developer</option>
                      <option value="Crypto Investor">Crypto Investor</option>
                      <option value="Sophisticated Investor / VC Firm">Sophisticated Investor / VC Firm</option>
                    </select>
                  </div>
                  <div className="form-floating">
                    <textarea
                     id="message"
                     name="message"
                      className="form-control"
                      placeholder="Message"
                    ></textarea>
                  </div>
                  <div className="d-grid buttons">
                    <div className="btn-style btn-style-one m-0">
                      {
                        this.state.processingForm ? 
                        <button className="glow-button gradient-border-mask">
                          <span>Processing...</span>
                        </button>:
                        <button type="submit" className="glow-button gradient-border-mask">
                          <span>Send Message</span>
                        </button>
                      }
                    </div>
                  </div>
                </form>
                
                </div>
              </div>
            </div>
          </section>
      </>
    );
  }
}
StartBuilding.propTypes = {
  theme: PropTypes.string,
};
